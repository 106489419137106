@import './Variables';

*{
    margin: 0;
    padding: 0;
}
a:hover,
a{
    text-decoration: none;
    color: inherit;
}
/* Custom Modal Size */
.custom-modal .modal-dialog {
    max-width: 800px; /* Increase width */
    max-height: 600px; /* Increase height */
  }
  .chat_msg {
    display: flex;
    justify-content: space-between; /* Pushes text and time apart */
    align-items: center; /* Aligns them vertically */
    gap: 8px; /* Adjust the spacing */
}

.chat_time {
    font-size: 0.8em; /* Adjust font size */
    color: gray; /* Optional: Change color for better visibility */
}

.chat_date_label {
    display: inline-block;  /* Makes it as small as the content */
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    font-size: 12px;
    font-weight: bold;
    padding: 5px 12px; /* Padding for better appearance */
    border-radius: 12px; /* Rounded corners */
    text-align: center;
    margin: 10px auto; /* Centering with auto margins */
}

.chat_date_container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.chat_msg_list_item {
    margin-bottom: 8px; /* Adds space between messages */
}

  .custom-modal .modal-header .btn-close {
    width: 20px;     /* Set desired width */
    height: 20px;    /* Set desired height */
    font-size: 12px; /* Adjust font size if applicable */
  }
body{
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}
.brand_color2{
    color: $brand_color2;
}
.brand_color3{
    color: $brand_color3;
}
.bg-transparent{
    background: transparent !important;
}
.bg-brand_color1{
    background: $brand_color1 !important;
}
.bg-brand_color2{
    background: $brand_color2 !important;
}
.bg-brand_color3{
    background: $brand_color3 !important;
}
.bg-brand_color4{
    background: $brand_color4 !important;
}
.bg-white_color_half{
    background: $white_color_half !important;
}
.bg-added_mob_color{
    background: $added_mob_color !important;
}
.opacity5{
    opacity: 0.5;
}
.trans_bdr_success{
    background-color: $brand_color2;
    border-color: $brand_color2;
    color: $brand_dark_color;
}
.btn.btn-block{
    width: 100%;
}
.btn.btn-success.disapprove {
    background-color: #E34D4D;
    border-color: #E34D4D;
    color: $brand_dark_color;
}
.btn.btn-success.disapprove:hover{
    background-color: $white_opacity05;
    border-color: $white_opacity05;
    color: $white_color ;

}

.btn.btn-success{
    background-color: $brand_color2;
    border-color: $brand_color2;
    color: $brand_dark_color;
}
.btn.btn-success:hover,
.btn.btn-success:first-child:hover{
    background-color: $white_opacity05;
    border-color: $white_opacity05;
    color: $white_color ;

}
.btn.btn-outline-success{
    border-color: $brand_color2;
    color: $brand_color2;
}
.btn.btn-outline-success:first-child:hover,
.btn.btn-outline-success:hover{
    background-color: $brand_color2;
    border-color: $brand_color2;
    color: $brand_color1;
}

.btn.btn-trans_success,
.btn.btn-trans_success:first-child:hover,
.btn.btn-trans_success:hover{
    background-color: $brand_color2_opacity1;
    border-color: $brand_color2_opacity1;
    color: $brand_color2 ;
}
.btn.btn-primary{
    background-color: $brand_color3 ;
    border-color: $brand_color3;
    color: $white_color ;
}
.btn.btn-primary:hover,
.btn.btn-primary:first-child:hover{
    background-color: $white_opacity05;
    border-color: $white_opacity05;
    color: $white_color ;

}
.btn.btn-light,
.btn.btn-light:first-child:hover,
.btn.btn-light:hover{
    background-color: $white_opacity05;
    border-color: $white_opacity05;
    color: $white_color ;
}
.btn.btn-dark{
    background-color: $brand_dark_color;
    border-color: $brand_dark_color;
    color: $white_color ;
}
.btn.btn-dark:hover,
.btn.btn-dark:first-child:hover{
    background-color: $white_opacity05;
    border-color: $white_opacity05;
    color: $white_color ;

}
.btn.btn-secondary,
.btn.btn-secondary:first-child:hover,
.btn.btn-secondary:hover{
    background-color: $brand_color1;
    border-color: $brand_color1;
    color: $white_color ;
}
.btn{
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    border-radius: $bdr_rds2;
    padding-left: 20px;
    padding-right: 20px;
}
.login-button.btn {
    background-color: #5de094 !important;
    color: #000 !important;
    border-color: #5de094 !important;
}
.login-button.btn:hover {
    background-color:#242529!important;
    border-color: rgba(255, 255, 255, 0.05) !important;
    color: #ffffff !important;
}
.login-button a{
    text-decoration: underline;
}
.btn.btn-sm{
    height: 30px;
    font-weight: 500;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: $bdr_rds2;
}
.btn.btn-md{
    height: 45px;
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: $bdr_rds2;
    display: inline-flex;
    align-items: center;
}
.btn.btn-lg{
    height: 60px;
    font-weight: 500;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: $bdr_rds3;
}

ul.search-table {
    position: absolute;
    background-color: #242529;
    z-index: 1;
    border-radius: 10px;
    font-size: 15px;
    margin-top: 10px;
    padding: 0;
}
li.search-list{
    list-style-type: none;
    padding: 10px 14px;
    border-bottom: 1px solid #6f767e;
    color: #6f767e;
}
li.search-list:last-child{
    border-bottom: none;
}




input.form-control,
.form-select,
input.form-control:disabled{
    height: 60px;
    outline: none !important;
    box-shadow:  none !important;
}
.form-select{
    background-image: url('../images//icons/dropdown_arrow.png');
    background-size: 12px;
}

.form-select-sm{
    height: auto;
}
.form-control.form-control-sm{
    height: 30px;
}
.form-control.form-control-md{
    height: 45px;
}
.form-control.form-control-lg{
    height: 60px;
}
.form-switch .form-check-input{
    width: 40px;
    background-color: $brand_color6;
    border-color: $brand_color6;
    outline: none !important;
    box-shadow: none !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
}
.form-switch .form-check-input:checked{
    background-color: $brand_color2;
    border-color: $brand_color2;
}

.form-select:disabled {
    opacity: 0.5;
    background-color: #212223 !important;
}

.assignment input.form-control:disabled {
    opacity: 0.5;
}


.change_plan_btn{
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    border: 0px;
    width: 150px;
    border-radius: 5px;
    color: $white_color;
    font-weight: 500;
    font-size: 14px;
}
.badge{
    border-radius: $bdr_rds2;
    height: 27px;
    // padding-left: 10px;
    // padding-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
}
.badge.bg-outline_primary{
    border: 2px solid $brand_color3;
    color: $white_color;
}
.bg-brand_color2{
    background-color: $brand_color2;
}
.bg-brand_color3{
    background-color: $brand_color3;
}
.bg-brand_color3_opacity2{
    background-color: $brand_color3_opacity2;
}
.bg-brand_color7_opacity1{
    background-color: $brand_color7_opacity1;
}
.bg-white_opacity1{
    background-color: $white_opacity1;
}
.dashboardLayout{
    background: $brand_dark_color;
    width: 100%;
    min-height: 100vh;
    display: flex;
}
.sidebar_container{
    width: 250px;
    background: $brand_color1;
    min-height: 100vh;
    // min-height: 1083px;
}
.main_body_container{
    width: 100%;
    width: calc(100% - 250px);
    position: relative;
    padding-bottom: 10px;
}
.main_body_content{
    // background-color: $brand_color1;
    min-height: 100vh;
    color: $white_color;
    position: relative;
}
.top_header{
    // position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    background-color: rgba(36, 37, 41, 0.6);
}
.logged_user{
    margin: 15px;
    display: block;
    text-align: center;
    text-decoration: none;
}
.logged_user_img{
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    margin: 10px auto;
    // border: 2px solid transparent;
}
.logged_user.active .logged_user_img{
    border: 2px solid $brand_color2;
}
.logged_user p{
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: $white_color;
    margin:  0px auto;
}
.logged_user .dropdown-toggle.btn-link:hover,
.logged_user .dropdown-toggle.btn-link{
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: $white_color;
    text-decoration: none;
    color: $white_color;
}
.logged_user_typ{
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    color: $white_color_half;
}
.sidebar_menu{
}
.sidebar_menu a{
    display: flex;
    align-items: center;
    margin: 5px 0px ;
    // background: rgba(255, 255, 255, 0.0);
    border-left: 3px solid transparent;
    // border-radius: 16px;
    height: 60px;
    padding: $p10 $p20 $p10 $p15;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    text-transform: capitalize;
    color: $light_color2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.sidebar_menu a:hover,
.sidebar_menu a.active{
    color: $white_color;
}
.sidebar_menu a:hover::before,
.sidebar_menu a.active::before{
    content: '';
    position: absolute;
    background: $brand_color2;
    height: 100%;
    width: 5px;
    left: -3px;
    top: 0;
    border-radius: 0 100px 100px 0px;
}
.sidebar_menu a .menu_icon{
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.sidebar_menu a .menu_icon svg{
    width: 25px;
    height: 25px;
}
.sidebar_menu .devider{
    color: $white_color;
    padding: $p10 $p20 $p10 $p20;
    margin-top: 10px;
}
.sidebar_menu a .badge{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $brand_color2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 10px;
    color: $brand_color1;
    padding: 0px;
}
.sidebar_menu a .path_fill{
    fill: rgba(255, 255, 255, 0.2);
}
.sidebar_menu a:hover .path_fill,
.sidebar_menu a.active .path_fill{
    fill: $white_color;
}

.sidebar_menu a .path_stroke{
    stroke: rgba(255, 255, 255, 0.2);
}
.sidebar_menu a:hover .path_stroke,
.sidebar_menu a.active .path_stroke{
    stroke: $white_color;
}
// side menu
.btn.toggle {
    background-color: transparent;
    border: 2px solid $brand_color2;
    overflow: hidden;
    width:50px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 3px 7px 0 #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}
.sidebar_container .btn.toggle {
    background-color: #242529;
    border: 0px;
    position: absolute;
    right: -50px;
    top: 0px;
    border-radius: 0px;
}
.line-toggle {
    position: absolute;
    display: block;
    width: 30px;
    height: 2px;
    background: $brand_color2;
    border-radius: 5px;
    transition: all .6s;
}
.line-toggle:first-child {
    transform: translateY(-10px) translateX(-3px);
    width: 24px;
    transition-delay: 0s;
} 
.toggle .line-toggle:nth-child(2) {
    transition-delay: .12s;
} 
.line-toggle:last-child {
    transform: translateY(10px) translateX(-6px);
    width: 17px;
    transition-delay: 0s;
}
// ...top_header
.top_header .input-group>.form-control, 
.top_header .input-group>.form-floating, 
.top_header .input-group>.form-select,
.top_header .input-group .btn{
    background: $brand_color1;
    border-color: $brand_color1;
    outline: none;
    box-shadow: none;
    color: $white_color;
    height: 55px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 20px;
}
.top_right_nav .nav-link{
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    // margin-top: 15px;
    background: $brand_color1;
    border-radius: $bdr_rds2;
    position: relative;
}
.top_right_nav .notification a > span{
    position: relative;
    display: block;
    width: 30px;
}
.top_right_nav .notify{
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #F9BE7A;
    border: 3px solid $brand_color5;
    color: $white_color;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    left: 10px;
    font-size: 10px;
}
.dropdown.user .btn{
    display: flex;
    align-items: center;
    color: $white_color !important;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
}
.dropdown.user span{
    width: 50px;
    height: 50px;
    display: inline-flex;
    background: $brand_color1;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.dropdown.user span img{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    padding: 3px;
}
.dropdown.user .dropdown-toggle::after{
    display: none;
}








// page style start now
.page_hdng{
    font-weight: 600;
    font-size: 30px;
    color: $white_color;
    text-transform: capitalize;
}
.card_outer_hdng{
    min-height: 40px;
}
.card_outer_hdng h3{
    font-weight: 600;
    font-size: 20px;
    color: $white_color;
    text-transform: capitalize;
    margin: 0;
}
.my_card{
    background-color: $brand_color1;
    padding: $p15;
    border-radius: $bdr_rds2;
}
.my_card_brand_dark_color{
    background-color: $brand_dark_color;
    padding: $p15;
    border-radius: $bdr_rds2;
}
.my_card_brand_color3{
    background-color: $brand_color3;
    padding: $p15;
    border-radius: $bdr_rds2;
}
.my_card hr{
    margin: 10px auto;
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 2px;
}
.assignments_date{
    display: flex;
    align-items: center;
}
.assignments_date .react-datepicker-wrapper {
    width: unset
}
.assignments_date button{
    background-color: transparent;
    border: 0px;
    display: flex;
    align-items: center;
    opacity: 0.5;
}
.assignments_date.school-admin button.select_date_box{
    background-color: #0b0c0d !important;
    opacity: 1 !important;
}
.assignments_date.school-admin-transactions button.select_date_box{
    background-color: #242529 !important;
    opacity: 1 !important;
}
.assign_date{
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
}


.nav-brand_pills .nav-item .nav-link {
    background: 0 0;
    border: 0;
    border: 3px solid $brand_dark_color;
    border-radius: $bdr_rds2;
    color: $white_color;
    background-color: $brand_dark_color;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
}
.nav-brand_pills .nav-item .nav-link.active, 
.nav-brand_pills .nav-item .show>.nav-link {
    color: $white_color;
    background-color: $brand_color2;
    border: 3px solid $brand_color2;
    color: $brand_color1;
}
.progress_row{
    display: flex;
    align-items: center;
}
.progress_icon{
    width: 30px;
}
.progressBar_bg{
    border: 1px solid $white_opacity05;
    padding: 5px 8px;
    border-radius: $bdr_rds2;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.progressBar_bg .progress{
    width: calc(100% - 40px);
    border-radius: $bdr_rds2;
    background: $brand_color4;
}
.progressBar_bg label{
    font-size: 10px;
}
.progressBar_bg  .progress .progress-bar.bg-brand_color2{
    background: $brand_color2;
}
.carousel-indicators{
    bottom: -40px;
}
.carousel-indicators{
    bottom: -40px;
}
.carousel-indicators [data-bs-target]{
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background-color: $brand_dark_color;
    opacity: 1;
}
.carousel-indicators [data-bs-target].active{
    background-color: $brand_color2;
}
.view_all_btn{
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.769286px;
    color: $white_color;
    background: $brand_dark_color;
    border: 0px;
}
.members img{
    width: 40px;
    height: 40px;
    margin:  0 auto;
    display: flex;
    border-radius: 50%;
    object-fit: cover;
}
.members h5{
    font-weight: 600;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
}
.members p{
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    margin: 0;
}
.basic_subscription h5{
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
}
.assignments_card{
    background: $brand_color1;
    border-radius: $bdr_rds2;
    padding: $p15;
    margin-top: 15px;
}
.assignments_card h4{
    color: $brand_color2;
    font-weight: 700;
    font-size: 34px;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.assignments_card p{
    color: $white_opacity3;
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    margin: 0;
}

.assignments_card.active{
    background: $brand_color3;
}
.assignments_card.active h4,
.assignments_card.active p{
    color: $white_color;
}
.assignments_date.primary{
    background-color: $brand_color3;
    border-radius: $bdr_rds2;
    width: 165px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
table tr td{
    padding-top: 6px;
    padding-bottom: 6px;
}
.transactions table{
    width: 100%;
}
.user_table table img{
    width: 50px;
    height: 50px;
}
.transactions table h6{
    font-weight: 500;
    font-size: 14px;    
}
.transactions table p{
    font-weight: 500;
    font-size: 12px; 
    color: $white_color_half;   
}
.transactions table .transact_amnt{
    font-weight: 500;
    font-size: 16px; 
    color: $brand_color2;  
    text-align: right; 
}


.fixed_tab{
    margin-top: 20px;
}
.nav-fixed_pills .nav-item .nav-link{
    background-color: rgba(36, 37, 41, 0.4);
    color: $white_color;
    font-weight: 500;
    font-size: 16px;
    padding: 15px 20px;
}
.nav-fixed_pills .nav-item .nav-link.active{
    background: $brand_color1;
}
.nav-fixed_pills .nav-item:first-child .nav-link{
    border-radius: $bdr_rds2 0px 0px;
}
.nav-fixed_pills .nav-item:last-child .nav-link{
    border-radius: 0px $bdr_rds2 0px 0px;
}
.tab-content{
    min-height: 100px;
    background: $brand_color1;
    border-radius: 0px $bdr_rds2 $bdr_rds2 $bdr_rds2;
    padding: 20px;
    color: $white_color;
}
.fixed_tab .table thead tr th{
    font-weight: 400;
    font-size: 14px;
    color: $brand_color2;
}
.fixed_tab .table tbody tr td{
    color: $white_color;
    font-size: 14px;
}
.assignment-inner{
    cursor: pointer;
}
.fixed_tab .table thead tr th,
.fixed_tab .table tbody tr td{
    vertical-align: middle;
    border-color: rgba(255, 255, 255, 0.1);
}

.fixed_tab .table tbody tr td h5{
    font-weight: 500;
    font-size: 16px;
    margin: 0;
}
.fixed_tab .table tbody tr td p{
    // opacity: 0.4;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.fixed_tab .table tbody tr td h5{
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}
.fixed_tab .table tbody tr td.paddsowd{
    color: $white_color;
}
.fixed_tab .table tbody tr td.paddsowd input{
    width: 60px;
    background: transparent;
    border: 0px;
    color: $white_color;
    opacity: 0.5;
}
.badge.badge_md{
    width: 40px;
    height: 40px;
    background: $white_opacity05;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}
.badge.badge_md img{
    width: 16px;
}

.user-name .badge {
    position: relative;
    left: 45px;
    // width: 15px;
    height: 17px;
    // border-radius: 50%;
    background: $brand_color2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 10px;
    color: $brand_color1;
    transform: translate(-45%,-75%)!important;
}

form .dfn_label{
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    color: $white_color_half;
}

.form-label{
    font-weight: 400;
    font-size: 16px;
    color: $brand_color2;
}
// input.form-control:-webkit-autofill,
.my_card_brand_dark_color input.form-control:-webkit-autofill
{
    -webkit-text-fill-color: $white_color !important;
    -webkit-box-shadow: 30px 30px 30px 40px $brand_color1  inset !important;
}
.form-control,
.form-select,
.form-control:focus,
.form-control:disabled{
    background-color: $brand_color1 ;
    border-radius: $bdr_rds2;
    color: $white_color;
    border: 0px;
    font-weight: 400;
    font-size: 16px;
    position: relative;
}

#student-profile-edit .form-control:disabled{
    opacity: 0.7;
}

.password_frm_grp.input-group  .form-control{
    border-radius:  $bdr_rds2 0px 0px $bdr_rds2;
}
.password_frm_grp.input-group .input-group-text{
    background-color: $brand_color1;
    border-radius:  0px $bdr_rds2 $bdr_rds2 0px ;
    border: 0px;
}
form .cancel_btn,
form .save_btn{
    width: 150px;
    height: 63px;
    border-radius: $bdr_rds2;
    border: 0px;
    font-weight: 500;
    font-size: 18px;
}
form .cancel_btn{
    background: rgba(255, 255, 255, 0.1);
    color: $white_color;
}
.back_btn{
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05);
    color: #ffffff;
}
form .save_btn{
    background: $brand_color2;
    color: $bdr_rds2;
}
.profile_dtls_user_img img{
    width: 100px;
    height: 100px;
    border-radius: $bdr_rds3;
    object-fit: cover;
    object-position: center;
    background-color: $brand_color1;
}
.change_card_btn{
    width: 130px;
    height: 50px;
    border: 2px dashed $brand_color3;
    border-radius: 16px;
    background: transparent;
    color: $brand_color3;
}
.payment_due_table tr td{
    border: 0px;
}
.payment_due_table tr td hr{
    margin: 0px;
    border-style: dashed;
}
.payment_due_table tr td:first-child{
    font-weight: 500;
    font-size: 14px;
}
.payment_due_table tr td:last-child{
    font-weight: 400;
    font-size: 16px;
    text-align: right;
}
.payment_due_pay_now_btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 53px;
    background: $brand_color2;
    color: $brand_color1;
    border-radius: $bdr_rds2;
    border: 0px;
    font-weight: 600;
    font-size: 18px;
    margin-left: auto;
}
table .pay_now_btn{
    width: 99px;
    height: 42px;
    background: $brand_color2;
    font-weight: 500;
    font-size: 16px;
    color: $brand_color1;
    border-radius: $bdr_rds2;
}
.modal .modal-content{
    background: $brand_color1; 
    color: $white_color;
    border-radius: 24px;
}
.modal .modal-header{
    border: 0px;
}



.modal .btn-close{
    position: absolute;
    width: 40px;
    height: 40px;
    right: -10px;
    top: -10px;
    opacity: 1;
    background: url('../images/icons/close.svg');
    background-color: $brand_color6;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    text-transform: capitalize;
}

.audio-btn-close {
    position: absolute;
    right: -11px;
    top: -10px;
    opacity: 1;
    background: url(http://localhost:3001/static/media/close.ffb8157….svg);
    background-color: #E34D4D;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    text-transform: capitalize;
    width: 2em;
    height: 2em;
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
    box-sizing: content-box;
    color: #fff;
    border: 0;
}

.modal .modal-content .form-control,
.modal .modal-content .form-select,
.modal .modal-content .form-control:focus,
.modal .modal-content .form-control:disabled{
    background-color: $white_opacity05 ;
    border-radius: $bdr_rds2;
    color: $white_color;
}
.modal .modal-content .form-select option{
    background-color: $brand_color1;
}
table .tbl_usr_img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.tbl_assigned_users_img{
    margin-left: 15px;
    position: relative;
    width: fit-content;
}
.tbl_assigned_users_img .tbl_usr_img{
    margin-left: -15px;
    width: 30px;
    height: 30px;
    position: relative;
}
.tbl_assigned_users_img span{
    width: 30px;
    height: 30px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    right: 0px;
    font-size: 10px;
    font-weight: 500;
}

.subscriptions_packages a{
    text-decoration: none;
    border: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
    display: block;
    border-radius: 20px;
    position: relative;
}

.subscriptions_packages a.active{
    background-color: $brand_dark_color;
}
.subscriptions_packages a::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $white_color;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.subscriptions_packages a.active::after{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0px;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../images/icons/check.png);
    background-size: cover;
}

.subscriptions_packages .packages_container{
    padding: 15px 25px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.subscriptions_packages .subscriptions_packages_name{
    margin-left: 50px;
}
.subscriptions_packages .subscriptions_packages_name h5{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: $white_color;
    text-transform: capitalize;
    margin: 0;
}
.subscriptions_packages .packages_container h4{
    color: $brand_color2;
    font-weight: 600;
    font-size: 35px;
    margin: 0;
}
.subscriptions_packages .packages_container h4 sub{
    color: $white_color;
    bottom: 0;
    font-weight: 400;
    font-size: 15px;
}
.my_type_subscriptions .tab-content{
    background: transparent;
}
.my_pill_tab_nav{
    background: $white_opacity05; 
    width: fit-content;   
    border-radius: 30px;
}
.my_type_subscriptions .my_pill_tab_nav{
    background: $white_opacity3; 
}
.my_pill_tab_nav .nav-item .nav-link{
    color: $white_opacity7;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;   
    border-radius: 30px;
    min-width: 110px;
}
.my_pill_tab_nav .nav-item .nav-link.active{
    background: $brand_color3;
    color: $white_color;
}
.my_type_subscriptions_content .nav-item{
    display: flex;
    width: 100%;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    color: $white_opacity7;
}
.my_type_subscriptions_content .nav-item::after{
    content: '';
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url(../images//icons/select_check.png);
    background-size: cover;
    background-position: center;
}
.form-check-input{
    background-color: transparent;
    border: 1px solid rgb(255 255 255 / 82%);
    width: 20px;
    height: 20px;
}
.form-check-input:checked {
    background-color: #8038F5;
    border-color: #8038F5;
}
.chat_active_uesr{
    display: flex;
    align-items: center;
}
.chat_active_uesr img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 15px;
}
.chat_active_uesr .online_mark{
    color: $brand_color2;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
}

.chat_active_uesr .offline_mark{
    color: $light_color2;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
}
.chat_active_uesr .online_mark::after{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $brand_color2;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}
.chat_msg_container{}
.chat_msg_list{
    list-style: none;
    margin-left: -30px;
    height: calc(100vh - 270px);
    overflow: auto;
}
.chat_msg_list::-webkit-scrollbar {
    display: none;
}
.chat_msg_list_item{
    display: flex;
    margin: 10px auto;
}
.chat_msg_list_item.msg_me{
    justify-content: flex-end;
}
.chat_msg_list_item.msg_other{
    justify-content: flex-start;
}
.chat_msg_list_item .chat_msg{
    width: fit-content;
    max-width: 600px;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
}


.chat_msg_list_item.msg_other .chat_msg{
    justify-content: flex-start;
    border-radius: 10px 10px 10px 0px;
    background-color: $brand_color2;
    color: $brand_color1;
}
.chat_msg_list_item.msg_me .chat_msg{
    justify-content: flex-end;
    border-radius: 10px 10px 0px 10px;
    background-color: $brand_color1;
    color: $white_opacity7;
}
.chat_msg_container .form-floating>label{
    color: $white_opacity7;
}
.chat_textarea{
    position: relative;
    background-color: $brand_color1;
    border-radius: $bdr_rds3;
}
.chat_textarea .form-control {
    width: calc(100% - 96px);
    background-color: transparent;
}
.chat_textarea .form-control.form-control:focus{
    box-shadow: none;
}
.chat_textarea form{
    border: 2px solid #545454;
    border-radius: 10px;
    }

.upload_btn_wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
.form-group .attach_btn{
    background: #242529;
    border-radius: 10px;
    padding: 8px 20px;
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: $brand_color2;
    border: 0px;
    margin-right: 10px;
}
.chat_textarea .attach_btn{
    background: rgba(93, 224, 148, 0.1);
    border-radius: 10px;
    padding: 8px 20px;
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: $brand_color2;
    border: 0px;
    margin-right: 10px;
}
.send_btn{
    width: 100px;
}
.upload_btn_wrapper input[type=file] {
    font-size: 100px;
    position: relative;
    left: 0;
    top: -41px;
    opacity: 0;
    height: 100%;
    border-radius: 9px;
    width: 100%;
    max-width: 191px;
    max-height: 35px;
    margin-top: 5px;
}
.chat_textarea_buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 92px;
    height: calc(3.5rem + 5px);
    right: 0;
    top: 0;
}






// Student Interface Style Start Here //
.video_card{
    background: $brand_dark_color;
    border-radius: $bdr_rds3;
    padding: $p15;
    margin-top: 15px;
}
.video_card>img{
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: $bdr_rds2;
}
.video_card .video_play_progress_status{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.video_card .video_play_progress_status .progress{
    height: 5px;
    width: calc(100% - 30px);
    border-radius: 10px;
    background: $brand_color1;
}

.video_card .video_play_progress_status .progress .progress-bar{
    background: $brand_color2;
}

.nav-brand_outline_pills .nav-item .nav-link{
    border: 2px solid $brand_color2;
    color: $white_color;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: $bdr_rds3;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
}
.nav-brand_outline_pills .nav-item .nav-link.active{
    border: 2px solid $brand_color2;
    color: $brand_dark_color;
    background: $brand_color2;
}
.list_seach .form-control, 
.list_seach .btn,
.list_seach .btn:focus, 
.list_seach .btn:hover,  
.list_seach .form-control:focus, 
.list_seach .form-control:disabled{
    height: 40px;
    background-color: $brand_dark_color;
    border: 0px;
}
.library_list_card{
    background: $brand_dark_color;
    border-radius: $bdr_rds3;
    padding: $p10;
    display: flex;
    align-items: center;
}
.chapter_list_card{
    background: $brand_dark_color;
    border-radius: $bdr_rds3;
    padding: $p10;
    position: relative;
    overflow: hidden;
}
.chapter_list_card .wishList{
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
}
.wishList .wishList_btn img{
    opacity: 0.5;
}
.wishList .wishList_btn,
.wishList .add_wishList_btn{
    background: transparent;
    border: 0px;
}

.wishList .wishList_btn svg path{
    fill: $white_color_half;
}
.wishList .add_wishList_btn svg path{
    fill: $brand_color6;
}

.chapter_list_card .progress{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: $brand_dark_color;
}
.chapter_list_card .progress .progress-bar{
    background-color: $brand_color2;
}
.sqr_video_thumb{
    position: relative;
}
.sqr_video_thumb>img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.sqr_video_thumb .play_icon{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    cursor: pointer;
}
.sqr_video_thumb .play_icon img{
    background-color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.profile_details_list .list-group{
    border-radius: 0px;
}
.profile_details_list .list-group-item{
    background-color: transparent;
    border: 0px;
    border-right: 1px solid $white_opacity3;
    border-radius: 0px !important;
    width: 100%;
}
.profile_details_list .list-group-item:last-child{
    border-right: 0px;
}
.chat_list_seach .form-control,
.chat_list_seach .btn{
    background-color: $white_opacity05 !important;
    height: 56px;
    border: 0px;
    outline: none !important;
    box-shadow: none !important;
}
.chat_contact .nav{
    background-color: $brand_color1;
    padding: 10px;
    border-radius: $bdr_rds2;
    margin: 10px auto;
}
.chat_contact .nav .nav-item{
    margin: 2px;
}
.chat_contact .nav .nav-link{
    font-size: 14px;
    padding: 7px 5px;
    margin: 0;
}
.chat_contact .chat_msg_list_item .chat_msg{
    max-width: 450px;
}
.chat_contact .tab-content{
    border-radius: $bdr_rds2;
}
.contact_list{
    background: $brand_dark_color;
    padding: 15px;
    border-radius: $bdr_rds2;
    margin-bottom: 10px;
}
.chat_contact .tab-content{
    padding: 15px;
    max-height: 65vh;
    overflow: auto;
}
.contact_list:last-of-type{
    margin-bottom: 0px;
}
.contact_list .btn-cancel{
    background: rgba(227, 77, 77, 0.1) !important;
    border: 1px solid #E34D4D !important;
    border-radius: 14px;
    color: #E34D4D;
}
.contact_list .btn-invite{
    background: rgba(93, 224, 147, 1) !important;
    border-radius: 14px;
    color: #191B1F;
}
.contact_list .btn-ignore{
    background-color: transparent !important;
    border: 1px solid $brand_color3 !important;
    color: $white_color !important;
    font-weight: 600;
    font-size: 12px;
    width: 55px;
    height: 30px;
    padding: 0;
    margin-left: 5px;
}
.contact_list .btn-accept{
    background-color: $brand_color2 !important;
    color: $brand_color1 !important;
    font-weight: 600;
    font-size: 12px;
    width: 55px;
    height: 30px;
    padding: 0;
    margin-left: 5px;
}

.video-react,
.video-react .video-react-poster,
.video-react .video-react-video{
    border-radius: $bdr_rds3;
    background: transparent;
    overflow: hidden;
}
.video-react .video-react-video{
    height: auto !important;
}
.video-react .video-react-big-play-button,
.video-react .video-react-big-play-button:hover,
.video-react:hover .video-react-big-play-button, 
.video-react .video-react-big-play-button:focus
{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    // border-radius: 50%;
    border: 0px;
    background: url(../images/icons/play.png);
    background-repeat: no-repeat;
    background-size: contain;
}
.video-react .video-react-big-play-button::before{
    display: none;
}
.list-group-flush>.list-group-item {
    padding: 0px;
    background-color: transparent;
    color: #ffffff;
    border: 0px !important;
}

.btn.btn-semi_primary,
.btn.btn-semi_primary:hover{
    color: #ffffff;
    background-color: rgba(128, 56, 245, 0.2);
    border-color: rgba(128, 56, 245, 0.2);
    border-radius: 14px;
}
.total_students{
    margin-left: 20px;
}
.total_students .total_students_img{
    border-radius: 50%;
    margin-left: -15px;
    position: relative;
}
.total_students .total_students_img img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.more_students{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    font-size: 10px;
    color: $brand_color2;
    font-weight: 500;
}
.select_date_box{
    width: 124px;
    height: 45px;    
    background: #242529;
    border: 0px;
    border-radius: $bdr_rds2;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    color: $white_color;
}
.no_records_loading {
    position: fixed;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
}

form .error {
    color: red;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.Toastify__toast-theme--light {
    background: #0B0C0D;
    color: #ffffff;
}
.sortByBtn .dropdown-toggle{
    text-decoration: none;
    padding: 0;
    color: $white_color !important;
}
.sortByBtn .dropdown-toggle::after{
    border: none;
    background-image: url('../images/icons/short_by.svg');
    width: 15px;
    height: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-left: 10px;
}
.sortByBtn .dropdown-menu{    
    background-color: $brand_dark_color;
    border: 1px solid $brand_color1;
}
.sortByBtn .dropdown-menu .form-check label{
    color: $white_color_half;
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
}
.filter_container{
    position: fixed;
    max-width: 400px;
    width: 100%;
    height: 100%;
    background-color: $added_mob_color;
    border-left: 1px solid $brand_dark_color;
    top: 0px;
    right: 0;
    z-index: +9;
    transition-duration: 500ms;
    padding: 15px;
    overflow-y: auto;
    overflow-x: visible;
}
// .filter_container{
//     direction:rtl; 
//     overflow:auto; 
// }
// .filter_container div{
//     direction:ltr;
// }
.filter_container::-webkit-scrollbar {
    width: 5px;
}
.filter_container::-webkit-scrollbar-track {
    background: $brand_color1;
}
.filter_container::-webkit-scrollbar-thumb {
    background: $white_opacity1;
}
.filter_container::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.filter_container.filter_deactive{
    right: -500px;
}
.filter_container.filter_active{
    right: 0px;
}
.filter_container .btn.close_filter{
    background: $brand_color1 !important;
    border: 1px solid $brand_color1 !important;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    z-index: +9;
}
.check_btn_toggle.form-check {
    padding: 0;
}
.check_btn_toggle .form-check-input {
    display: none;
}
.check_btn_toggle .form-check-input~.form-check-label {
    background: rgba(128, 56, 245, 0.1);
    border: 1px solid #8038F5;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
}
.check_btn_toggle .form-check-input:checked~.form-check-label {
    background-color: $brand_color2;
    border-color: $brand_color2;
    color: #242529;
}



.check_success_btn_toggle.form-check {
    padding: 0;
}
.check_success_btn_toggle .form-check-input {
    display: none;
}
.check_success_btn_toggle .form-check-input~.form-check-label {
    background: $brand_color1;
    border: 2px solid $white_opacity1;
    color: $white_color;
    font-weight: 600;
    font-size: 14px;
    border-radius: 40px;
    padding: 5px 20px;
    cursor: pointer;
}
.check_success_btn_toggle .form-check-input:checked~.form-check-label {
    background-color: $brand_color2;
    border-color: $white_opacity1;
    color: $white_color;
}


.breadcrumb{
    margin: 0;
}
.breadcrumb .breadcrumb-item{
    color: $brand_color2;
}
.breadcrumb-item+.breadcrumb-item::before{
    background-image: url('../images/icons/arrow.svg');
    width: 30px;
    height: 20px;
    background-size: contain;
    background-position: 4px 2px;
    background-repeat: no-repeat;
    content: '';
}
.addedCategoryCard{
    background: #191B1F;
    padding: 15px;
    border-radius: 30px;
    position: relative;
    margin: 15px auto;
}
.close_category_btn{
    position: absolute;
    top: -10px;
    right: -10px;
    width: 40px;
    height: 40px;
    background: $brand_color6;
    border: 0px;
    border-radius: 50%;
}
.form-group .choosen_file_control{
    background: $brand_color1;
    border-radius: 14px;
    padding: 10px;
}
.form-group .choosen_file_control .attach_btn{
    height: 40px;
}
.assignments_table th{
    font-weight: 400;
    color: $brand_color2;
    font-size: 12px;
}
.assignments_table td{
    font-weight: 400;
    color: $white_color;
    font-size: 12px;
}
.btn.btn-URL_File,
.btn.btn-URL_File:hover{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $brand_color1;
    border: 2px solid $brand_color2;
    border-radius: 14px;
    color: $white_color;
}

.btn-check+.btn-success{
    background: rgba(93, 224, 148, 1);
    height: auto;
}
.btn-check:checked+.btn-success{
    background: rgba(93, 224, 148, 0.2);
    color: #5DE094;
    border-color: transparent;
}
.fixed_audio_player{
    z-index: +99;
    position: fixed;
    right: 50px;
    bottom: 30px;
    width: 500px;
    // height: 250px;
    background: #0B0C0D;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.1);
}
.badge.bg-outline-primary{
    border: 1px solid $brand_color3;
}

.rhap_container .rhap_play-pause-button  svg path{
    fill: $brand_color2;
}
.rhap_progress-bar-show-download,
.rhap_download-progress{
    background-color: $white_opacity1;
}
.rhap_progress-indicator{
    display: none;
}
.rhap_progress-filled{
    background-color: $brand_color2;
}
.rhap_container{
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
}
.rhap_additional-controls{
    display: none;
}
.rhap_volume-controls{
    position: absolute;
    right: 20px;
    width: 100px;
}
.rhap_progress-section{
    left: 75px;
    position: absolute;
    width: calc(100% - 200px);
    bottom: 40px;
}
.pagination .page-item .page-link{
    border-color: $white_opacity1;
    background-color: $brand_color1;
    color: $brand_color2;
}
.pagination .page-item.active .page-link{
    border-color: $white_opacity1;
    background-color: $brand_color2;
    color: $brand_color1;
}

.pagination .page-item.disabled{
    display: none;
}

.p-icon.p-multiselect-trigger-icon{
    display: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-multiselect-panel{
    background: #242529;
    color: #ffffff;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    color: #ffffff !important;
    background: #242529 !important;
    box-shadow: none !important;
}
.p-multiselect-panel .p-multiselect-header{
    border-bottom: 1px solid rgba(255, 255, 255, 0.177);
    background: #242529;
    color: #ffffff;
}
.invoice_container{
    background-color: $white_color;
    // min-height: 800px; 
    color: $brand_dark_color;
}
.invoice_header{
    padding: 20px;
    margin-top: 20px;
}
.invoice_header h4{
    font-weight: 700;
    font-size: 32px;
}
.invoice_header h5{
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}
.invoice_header p{
    font-weight: 400;
    font-size: 10px;
}
.invoice_header_right{
    direction: rtl;
}
.invoice_body{
    padding: 20px;
    // padding-bottom: 100px;
}
.invoice_body table.rt_table  th{
    font-weight: 700;
    font-size: 10px;
    color: $brand_dark_color;
    padding-left: 0px;
    padding-right: 0px;
}
.invoice_body table.rt_table  td{
    font-weight: 400;
    font-size: 10px;
    color: $brand_dark_color;
    padding-left: 0px;
    padding-right: 0px;
}
.invoice_body table.rt_table th,
.invoice_body table.rt_table td{
    border-bottom: 1px solid #000000;
}
.invoice_body table.rt_table tbody tr:last-child th,
.invoice_body table.rt_table tbody tr:last-child td
{
    border-bottom: 0px;
}
.invoice_body hr{
    border-style: dashed;
    border-width: 1px;
}
.invoice_amount {
    padding: 20px;
}
.invoice_footer{
    background: #5DE094;
    padding: 35px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    position: relative; */
}
.invoice_footer a{
    position: absolute;
    left: 100px;
}
.invoice_footer h3{
    font-weight: 700;
    font-size: 45px;
    margin: 0;
    text-align: center;
}
.invoice_footer p{
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

// .video-react.video-react-fluid {
//     height: 200px;
//     padding-top: 0 !important;
// }

.modal .modal-footer .btn.btn-danger {
    background-color: #E34D4D;
}

.approve-modal .modal-footer {
    border-top: none;
}

#progress-text {
    margin-bottom: 0;
}

#change-password p {
    margin-bottom: 0;
    color: #a2aab1;
}

.subscriptions_packages.plans {
    height: 65vh;
    overflow: auto;
}

.change_plan_btn.discount {
    border-radius: 25px;
    padding: 0;
}

.video-preview {
    width: 100%;
    height: 1000%;
    max-width: 600px;
    max-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


input:autofill {
  border: 3px solid darkorange;
}

input:-webkit-autofill {
  border: 3px solid darkorange;
}